'use strict'

import axios from '@lib/axios'

const login = async data => {
  try {
    const result = await axios.post('/auth/login', data, { withCredentials: true })
    return result.data
  } catch (error) {
    return error.response.data
  }
}

const isLogged = async (config = null) => {
  try {
    const result = await axios.get('/auth/user', config)
    return result.data
  } catch (error) {
    if (!error.response || error.response.data.unauthorized) {
      return false;
    }
  }
}

const logout = async (config = null) => {
  try {
    const result = await axios.post('/auth/logout', {}, config)
    return !!result.data
  } catch {
    return false
  }
}

const ping = async (config = null) => {
  try {
    const result = await axios.get('/auth/ping', config)
    return result.data
  } catch {
    return false
  }
}

export default {
  logout,
  login,
  isLogged,
  ping
}