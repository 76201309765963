'use strict'

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import authApi from '@api/auth'
import { useRouter } from 'next/router'
import { userLogin } from '@state/action/auth'

const LayoutAuth = ({ children }) => {
  const state = useSelector((state) => state.auth.loggedIn) || {}
  const dispatch = useDispatch()
  const router = useRouter()

  const [isAuthenticating, setIsAuthenticating] = useState(false)

  useEffect(() => {
    checkLogin()

    async function checkLogin() {
      if (state.isLoggedIn) {
        setIsAuthenticating(false)

        const result = await authApi.isLogged({ withCredentials: true })
        const user = result?.data?.user
        const isLoggedIn = user ? true : false

        dispatch(
          userLogin({
            isLoggedIn,
            user,
          })
        )

        setIsAuthenticating(false)
      }
    }
  }, [router.asPath, state.isLoggedIn, dispatch])

  if (isAuthenticating && state.isLoggedIn) {
    return <p>Loading...</p>
  }

  return <>{children}</>
}

export default LayoutAuth
